import React from 'react'
import {
  BrowserRouter,
  Switch,
  Route,
  Redirect
} from 'react-router-dom'
import CssBaseline from '@material-ui/core/CssBaseline'
import { ThemeProvider } from '@material-ui/core'

import theme from './theme'
import { Provider } from 'react-redux'
import store from './redux/store'
import Page404 from './pages/404'
import Success from './pages/Success'
import FormWrapper from './pages/FormWrapper'
import Error from './pages/Error'

function App () {
  return (
    <Provider store={store()}>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Switch>
            <Route exact path='/registration/:eventDateId/:activeStep'>
              <FormWrapper />
            </Route>
            <Route exact path='/404'>
              <Page404 />
            </Route>
            <Route exact path='/success'>
              <Success />
            </Route>
            <Route exact path='/error'>
              <Error />
            </Route>
            <Route path='*'>
              <Redirect to='/404' />
            </Route>
          </Switch>
        </BrowserRouter>
      </ThemeProvider>
    </Provider>
  )
}

export default App
