import React from 'react'
import {
  Card,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(5)
  }
}))

const RadioCard = (props) => {
  const classes = useStyles()

  return (
    <Card
      className={classes.root}
    >
      <CardHeader
        title={props.title}
        subheader={props.subheader}
      />
      <CardContent>
        <RadioGroup
          name={props.title}
          value={props.value}
          onChange={props.onChange}
        >
          {props.options.map(option =>
            <FormControlLabel
              key={option._id}
              value={option._id}
              control={<Radio color='primary' />}
              label={props.optionLabel(option)}
            />
          )}
        </RadioGroup>
        <Typography
          color='error'
          variant='caption'
        >
          {props.error && props.helperText}
        </Typography>
      </CardContent>
    </Card>
  )
}

export default RadioCard
