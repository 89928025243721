import { SET_EVENT_DATE } from '../actions'

const INITIAL_STATE = {}

export const eventDate = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_EVENT_DATE:
      return {
        ...action.payload
      }
    default:
      return state
  }
}
