import { SET_STEP } from '../actions'

const INITIAL_STATE = 2

export const step = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_STEP:
      return action.payload
    default:
      return state
  }
}
