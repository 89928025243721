import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import DoneIcon from '@material-ui/icons/Done'

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  marginBottom: {
    marginBottom: theme.spacing(2)
  }
}))

const Success = props => {
  const classes = useStyles()

  return (
    <Container className={classes.root}>
      <Grid container justify='center'>
        <Grid item xs={12} sm={8} md={6} direction='column' align='center'>
          <DoneIcon fontSize='large' />
          <Typography variant='h4' className={classes.marginBottom}>POTWIERDZENIE REJESTRACJI</Typography>
          <Typography variant='h6'>Dziękujemy za zapisanie się na wyjazd!</Typography>
          <Typography variant='body2'>
            W ciągu kilku minut zostanie wysłana wiadomość email z potwierdzeniem przyjęcia rejestracji.
            Jeżeli nie otrzymają Państwo wiadomości, prosimy o kontakt telefoniczny.
          </Typography>
          <Typography variant='body2' className={classes.marginBottom}>
            UWAGA: Jeżeli wiadomość trafi do spamu, prosimy zaakceptować nasz adres email jako bezpiecznych.
            Dzięki temu kolejne wiadomości z informacjami o wyjeździe trafią do normalnej skrzynki.
          </Typography>
          <Typography variant='h6'>Co dalej?</Typography>
          <Typography variant='body2' className={classes.marginBottom}>
            W ciągu kilku dni zweryfikujemy Państwa rejestrację oraz wyślemy wiadomość email z dokumentami i informacjami o płatnościach.
          </Typography>
          <Typography variant='h5' color='primary'>Do zobaczenia!</Typography>
        </Grid>
      </Grid>
    </Container>
  )
}

export default Success
