import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import CloseIcon from '@material-ui/icons/Close'

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  marginBottom: {
    marginBottom: theme.spacing(2)
  }
}))

const Error = props => {
  const classes = useStyles()

  return (
    <Container className={classes.root}>
      <Grid container justify='center'>
        <Grid item xs={12} sm={8} md={6} direction='column' align='center'>
          <CloseIcon fontSize='large' />
          <Typography variant='h4' className={classes.marginBottom}>Ups, coś poszło nie tak :(</Typography>
          <Typography variant='body2' className={classes.marginBottom}>
            Wygląda na to, że zapisanie się na obóz jest niemożliwe w tej chwili.
          </Typography>
          <Typography variant='h6'>Co dalej?</Typography>
          <Typography variant='body2' className={classes.marginBottom}>
            Spróbuj ponownie za kilka minut, a jeśli błąd będzie się powtarzać, skontaktuj się z nami.
          </Typography>
          <Typography variant='subtitle2'>
            Telefon: <a href='tel:666666700'>666 666 700</a>
          </Typography>
          <Typography variant='subtitle2'>
            Email: <a href='mailto: biuro@szatanek.pl'>biuro@szatanek.pl</a>
          </Typography>
        </Grid>
      </Grid>
    </Container>
  )
}

export default Error
