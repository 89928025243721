import React, { useCallback, useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Grid } from '@material-ui/core'
import { setStep } from '../../redux/actions/step'
import RadioCard from '../RadioCard'
import { setFormData } from '../../redux/actions/formData'
import { useHistory, useParams } from 'react-router'

const Step2 = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { eventDateId } = useParams()

  const formData = useSelector(state => state.formData)
  const eventDate = useSelector(state => state.eventDate)
  const discounts = useSelector(state => state.discounts)

  const { handleSubmit, control } = useForm({
    defaultValues: formData
  })

  const onSubmit = useCallback(async (values) => {
    dispatch(setFormData(values))
    dispatch(setStep(3))
    history.push(`/registration/${eventDateId}/3`)
  }, [])

  const onBack = () => history.push(`/registration/${eventDateId}/1`)

  const onChange = useCallback(([event, id, asd]) => {
    return {
      value: id
    }
  }, [])

  const options = useMemo(() => {
    const defaultOption = {
      _id: '__default',
      name: 'brak zniżki',
      price: 0
    }
    const options = eventDate.discounts
      .filter(({ discount: discountId }) => {
        const discount = discounts[discountId]

        if (!discount) return false

        const now = Date.now()
        const activeFromDate = new Date(discount.activeFromDate)
        const activeToDate = new Date(discount.activeToDate)

        if (
          now < activeFromDate ||
          now > activeToDate
        ) {
          return false
        }

        return true
      })
      .map(({ discount: discountId }) => discounts[discountId])

    return [
      defaultOption,
      ...options
    ]
  }, [eventDate.discounts])

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Controller
        as={RadioCard}
        name='discount'
        title='Zniżki'
        subheader='Prosimy o uczciwe wybieranie należytej zniżki. Każda wybrana zniżka zostanie zweryfikowana, a więc cena końcowa może ulec zmianie.'
        options={options}
        control={control}
        onChange={onChange}
        optionLabel={option => <>{option.name} ({option.price} zł) <i>{option.description}</i></>}
        defaultValue='__default'
      />
      <Grid container justify='space-between'>
        <Grid item>
          <Button
            variant='contained'
            onClick={onBack}
          >
            Powrót
          </Button>
        </Grid>
        <Grid item>
          <Button
            type='submit'
            variant='contained'
            color='primary'
          >
            dalej
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}

export default Step2
