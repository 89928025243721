import React, { useCallback, useMemo, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import {
  TextField,
  Typography,
  Button,
  Grid,
  Card
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { setFormData } from '../../redux/actions/formData'
import { setStep } from '../../redux/actions/step'
import DatePicker from '../DatePicker'
import sub from 'date-fns/sub'
import compareAsc from 'date-fns/compareAsc'
import format from 'date-fns/format'
import CardContent from '@material-ui/core/CardContent'
import { useHistory, useParams } from 'react-router'

const useStyles = makeStyles(theme => ({
  root: {
    '& .FormField-container': {
      width: '100%',
      display: 'flex'
    },
    '& .MuiTextField-root': {
      width: '100%'
    },
    '& .MuiCard-root': {
      marginBottom: theme.spacing(5)
    }
  }
}))

const Step0 = () => {
  const classes = useStyles()
  const history = useHistory()
  const { eventDateId } = useParams()

  const dispatch = useDispatch()
  const formData = useSelector(state => state.formData)

  const [dateOfBirth, setDateOfBirth] = useState(formData.dateOfBirth)

  const isUnder18 = useMemo(() => {
    if (
      dateOfBirth &&
      dateOfBirth.getTime() // check is valid date object
    ) {
      const nowDate = sub(Date.now(), {
        years: 18
      })
      const isUnder18 = compareAsc(dateOfBirth, nowDate)
      if (isUnder18 === 1) return true
    }

    return false
  }, [dateOfBirth])

  const {
    handleSubmit,
    register,
    errors,
    getValues,
    control
  } = useForm({
    defaultValues: {
      ...formData,
      dateOfBirth: formData.dateOfBirth || null // workaround to make dateOfBirth input empty
    }
  })

  const onSubmit = useCallback(async (values) => {
    // fix for time
    const dateString = `${format(values.dateOfBirth, 'y-MM-dd')}T12:00:00Z`

    await dispatch(setFormData({
      ...values,
      dateOfBirth: new Date(dateString)
    }))
    await dispatch(setStep(1))
    history.push(`/registration/${eventDateId}/1`)
  }, [])

  return (
    <form className={classes.root} onSubmit={handleSubmit(onSubmit)} noValidate>
      <Card>
        <CardContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant='button'>Dane uczestnika</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id='firstName'
                label='Imię'
                name='firstName'
                placeholder='Michał'
                variant='outlined'
                error={!!(errors.name && errors.name.message)}
                helperText={errors.name && errors.name.message}
                required
                inputRef={register({
                  required: 'Pole wymagane'
                })}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id='lastName'
                label='Nazwisko'
                placeholder='Szatanek'
                name='lastName'
                variant='outlined'
                error={!!(errors.lastName && errors.lastName.message)}
                helperText={errors.lastName && errors.lastName.message}
                required
                inputRef={register({
                  required: 'Pole wymagane'
                })}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                as={DatePicker}
                control={control}
                name='dateOfBirth'
                id='dateOfBirth'
                placeholder='17/12/1992'
                label='Data urodzenia'
                format='dd/MM/yyyy'
                views={["year", "month", "date"]}
                openTo="year"

                value={getValues().dateOfBirth}
                error={!!(errors.dateOfBirth && errors.dateOfBirth.message)}
                helperText={errors.dateOfBirth && errors.dateOfBirth.message}
                onChange={(date, value) => {
                  setDateOfBirth(date[0])
                  return {
                    value: date[0]
                  }
                }}
                rules={{
                  required: 'Pole wymagane',
                  validate: value => {
                    if (
                      value &&
                      !value.getTime()
                    ) return 'Niepoprawny format daty. Wymagany: dd/mm/yyyy'
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id='pesel'
                label='PESEL'
                placeholder='12345678901'
                name='pesel'
                error={!!(errors.pesel && errors.pesel.message)}
                helperText={errors.pesel && errors.pesel.message}
                variant='outlined'
                required
                inputRef={register({
                  required: 'Pole wymagane',
                  maxLength: {
                    value: 11,
                    message: 'Za długi PESEL'
                  },
                  minLength: {
                    value: 11,
                    message: 'Za krótki PESEL'
                  }
                })}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant='button'>Adres korespondencyjny</Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                id='addressStreet'
                label='Ulica i numer'
                placeholder='Mazurska 30'
                name='addressStreet'
                variant='outlined'
                error={!!(errors.addressStreet && errors.addressStreet.message)}
                helperText={errors.addressStreet && errors.addressStreet.message}
                required
                inputRef={register({
                  required: 'Pole wymagane'
                })}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id='addressPostal'
                label='Kod pocztowy'
                placeholder='00-000'
                name='addressPostal'
                variant='outlined'
                error={!!(errors.addressPostal && errors.addressPostal.message)}
                helperText={errors.addressPostal && errors.addressPostal.message}
                required
                inputRef={register({
                  required: 'Pole wymagane',
                  pattern: {
                    value: /^[0-9]{2}-[0-9]{3}$/,
                    message: 'Nieprawidłowy kod'
                  }
                })}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id='addressCity'
                label='Miejscowość'
                placeholder='Ryn'
                name='addressCity'
                variant='outlined'
                error={!!(errors.addressCity && errors.addressCity.message)}
                helperText={errors.addressCity && errors.addressCity.message}
                required
                inputRef={register({
                  required: 'Pole wymagane'
                })}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant='button'>Dane kontaktowe</Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                id='email'
                label='Email'
                placeholder='michal@szatanek.pl'
                name='email'
                variant='outlined'
                error={!!(errors.email && errors.email.message)}
                helperText={errors.email && errors.email.message}
                required
                inputRef={register({
                  required: 'Pole wymagane',
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: 'Nieprawidłowy adres email'
                  }
                })}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id='email2'
                label='Potwierdź email'
                name='email2'
                variant='outlined'
                error={!!(errors.email2 && errors.email2.message)}
                helperText={errors.email2 && errors.email2.message}
                required
                inputRef={register({
                  required: 'Pole wymagane',
                  validate: value => {
                    const values = getValues()
                    return values.email === value || 'Adres email musi gadzać się z podanym powyżej'
                  }
                })}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id='phone'
                label='Telefon'
                placeholder='+48 666 666 700'
                name='phone'
                variant='outlined'
                error={!!(errors.phone && errors.phone.message)}
                helperText={errors.phone && errors.phone.message}
                required
                inputRef={register({
                  required: 'Pole wymagane'
                })}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id='secondPhone'
                label='Telefon 2'
                name='phone2'
                variant='outlined'
                inputRef={register}
              />
            </Grid>
            {
              isUnder18 && <>
                <Grid item xs={12}>
                  <Typography variant='button'>opiekun</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id='tutorName'
                    label='Imie i nazwisko'
                    name='tutorName'
                    variant='outlined'
                    error={!!(errors.tutorName && errors.tutorName.message)}
                    helperText={errors.tutorName && errors.tutorName.message}
                    required
                    inputRef={register({
                      required: 'Pole wymagane'
                    })}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id='tutorPhone'
                    label='Telefon'
                    name='tutorPhone'
                    variant='outlined'
                    error={!!(errors.tutorPhone && errors.tutorPhone.message)}
                    helperText={errors.tutorPhone && errors.tutorPhone.message}
                    required
                    inputRef={register({
                      required: 'Pole wymagane'
                    })}
                  />
                </Grid>
              </>
            }
          </Grid>
        </CardContent>
      </Card>

      <Grid container justify='flex-end'>
        <Grid item>
          <Button
            type='submit'
            variant='contained'
            color='primary'
          >
            Dalej
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}

export default Step0
