import React, { useCallback, useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Button, Grid, Box } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'

import RadioCard from '../RadioCard'
import { setStep } from '../../redux/actions/step'
import { setFormData } from '../../redux/actions/formData'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory, useParams } from 'react-router'

const useStyles = makeStyles(theme => ({
  noData: {
    minHeight: 100,
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center'
  }
}))

const Step1 = () => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const history = useHistory()
  const { eventDateId } = useParams()

  const formData = useSelector(state => state.formData)
  const eventDate = useSelector(state => state.eventDate)
  const extensions = useSelector(state => state.extensions)

  const { handleSubmit, control, errors } = useForm({
    defaultValues: formData.extensions
  })

  const onSubmit = useCallback((values) => {
    dispatch(setFormData({
      extensions: values
    }))
    dispatch(setStep(2))
    history.push(`/registration/${eventDateId}/2`)
  }, [])

  const onBack = useCallback(() => history.push(`/registration/${eventDateId}/0`), [])

  const onChange = useCallback(([event, id]) => {
    return {
      value: id
    }
  }, [])

  const extensionsToRender = useMemo(() => {
    return eventDate.extensions.filter(({ extension: extensionId }) => {
      const extension = extensions[extensionId]

      if (!extension) return false

      const now = Date.now()
      const activeFromDate = new Date(extension.activeFromDate)
      const activeToDate = new Date(extension.activeToDate)

      if (
        now < activeFromDate ||
        now > activeToDate
      ) {
        return false
      }

      if (!extension.options.length) return false

      return true
    })
  }, [eventDate.extensions])

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      {
        !extensionsToRender.length ? <Box className={classes.noData}>Brak dostępnych rozszerzeń oferty</Box> : null
      }
      {extensionsToRender.map(({ extension: extensionId }) => {
        const extension = extensions[extensionId]

        return (
          <Controller
            key={extensionId}
            as={RadioCard}
            name={extension._id}
            title={extension.name}
            options={extension.options}
            subheader={extension.description}
            control={control}
            onChange={onChange}
            optionLabel={option => `${option.name} (${option.price} zł)`}
            error={!!(errors[extension._id] && errors[extension._id].message)}
            helperText={errors[extension._id] && errors[extension._id].message}
            required
            defaultValue={null}
            rules={{
              required: 'Pole wymagane'
            }}
          />
        )
      })}
      <Grid container justify='space-between'>
        <Grid item>
          <Button
            variant='contained'
            onClick={onBack}
          >
            Powrót
          </Button>
        </Grid>
        <Grid item>
          <Button
            type='submit'
            variant='contained'
            color='primary'
          >
            Dalej
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}

export default Step1
