import { combineReducers } from 'redux'
import { discounts } from './discounts'
import { step } from './step'
import { formData } from './formData'
import { event } from './event'
import { eventDate } from './eventDate'
import { extensions } from './extensions'

const rootReducer = combineReducers({
  discounts,
  step,
  formData,
  event,
  eventDate,
  extensions
})

export default rootReducer
