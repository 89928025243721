import React, { useMemo } from 'react'
import { Card, Typography, Divider } from '@material-ui/core'
import format from 'date-fns/format'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import { useSelector } from 'react-redux'

const Discounts = (props) => {
  if (!props.discountsArray.length) {
    return null
  }

  return (
    <>
      <Divider />
      <CardContent>
        <Typography variant='subtitle1'>
          Zniżki:
        </Typography>
        {
          props.discountsArray.map(discount => (
            <Typography
              key={discount._id}
            >
              <b>{discount.name}:</b> {discount.price} zł
            </Typography>
          ))
        }
      </CardContent>
    </>
  )
}

const Extensions = (props) => {
  if (!props.extensionsArray.length) {
    return null
  }

  return (
    <>
      <Divider />
      <CardContent>
        <Typography variant='subtitle1'>
          Rozszerzenia oferty:
        </Typography>
        {
          props.extensionsArray.map(extension => (
            <Typography
              key={extension._id}
            >
              <b>{extension.name}:</b> {extension.option.name} ({extension.option.price} zł)
            </Typography>
          ))
        }
      </CardContent>
    </>
  )
}

const Price = props => {
  const price = useMemo(() => {
    let price = props.eventDate.price

    props.extensionsArray.forEach(extension => {
      price += extension.option.price
    })

    props.discountsArray.forEach(discount => {
      price += discount.price
    })

    return price
  }, [props.eventDate.price, props.extensionsArray, props.discountsArray])

  return (
    <>
      <Divider />
      <CardContent>
        <Typography variant='subtitle1'>
          Podsumowanie:
        </Typography>
        <Typography variant='h5'>
          <b>Cena całkowita:</b> {price} zł
        </Typography>
      </CardContent>
    </>
  )
}

const CampCard = () => {
  const eventDate = useSelector(state => state.eventDate)
  const event = useSelector(state => state.event)
  const extensions = useSelector(state => state.extensions)
  const discounts = useSelector(state => state.discounts)
  const formData = useSelector(state => state.formData)

  const dateFrom = useMemo(() => format(new Date(eventDate.dateFrom), 'd/MM/yyyy'), [eventDate])
  const dateTo = useMemo(() => format(new Date(eventDate.dateTo), 'd/MM/yyyy'), [eventDate])

  const extensionsArray = useMemo(() => {
    const extensionArray = []

    Object.keys(formData.extensions || {}).forEach(key => {
      const extension = extensions[key]
      const optionId = formData.extensions[key]
      const option = extension.options.find(option => option._id === optionId)

      extensionArray.push({
        ...extension,
        option
      })
    })

    return extensionArray
  }, [formData.extensions])

  const discountsArray = useMemo(() => {
    if (!formData.discount || formData.discount === '__default') {
      return []
    }

    return [
      discounts[formData.discount]
    ]
  }, [formData.discount])

  return (
    <Card>
      <CardHeader
        title={<>Rejestracja na: <b>{eventDate.name}</b></>}
        subheader={event.name}
      />
      <Divider />
      <CardContent>
        <Typography variant='subtitle1'>
          Informacje:
        </Typography>
        <Typography>
          <b>Termin:</b> {dateFrom} - {dateTo}
        </Typography>
        <Typography>
          <b>Miejsce:</b> {event.localization}
        </Typography>
        <Typography>
          <b>Cena podstawowa:</b> {eventDate.price} zł
        </Typography>
      </CardContent>
      <Extensions
        extensionsArray={extensionsArray}
      />
      <Discounts
        discountsArray={discountsArray}
      />
      <Price
        eventDate={eventDate}
        extensionsArray={extensionsArray}
        discountsArray={discountsArray}
      />
    </Card>
  )
}

export default CampCard
