import { SET_FORM_DATA } from '../actions'

const INITIAL_STATE = {
  extensions: {}
}

export const formData = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_FORM_DATA:
      return {
        ...state,
        ...action.payload
      }
    default:
      return state
  }
}
