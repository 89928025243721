import React from 'react'
import { Step, StepLabel, Stepper } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: 'transparent',
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  }
}))

const steps = ['Dane uczestnika', 'Rozszerzenia oferty', 'Zniżki', 'Podsumowanie']

const FormStepper = ({ activeStep }) => {
  const classes = useStyles()

  return (
    <Stepper className={classes.root} activeStep={activeStep} alternativeLabel>
      {steps.map(step =>
        <Step key={step}>
          <StepLabel>{step}</StepLabel>
        </Step>
      )}
    </Stepper>
  )
}

export default FormStepper
