import { SET_EXTENSIONS } from '../actions'

const INITIAL_STATE = {}

export const extensions = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_EXTENSIONS:
      return {
        ...state,
        ...action.payload
      }
    default:
      return state
  }
}
