import React, { useMemo } from 'react'
import { useParams } from 'react-router'
import Step0 from './Step0'
import Step1 from './Step1'
import Step2 from './Step2'
import Step3 from './Step3'

const ActiveStep = {
  0: Step0,
  1: Step1,
  2: Step2,
  3: Step3
}

const Form = props => {
  const { activeStep } = useParams()

  const Step = useMemo(() => ActiveStep[activeStep], [activeStep])

  return <Step {...props} />
}

export default Form
