import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import client from '../client'
import {
  Container,
  Grid,
  Backdrop,
  CircularProgress,
  Typography
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import FormStepper from '../componens/FormStepper'
import CampCard from '../componens/CampCard'
import FormMobileStepper from '../componens/FormMobileStepper'
import Form from '../componens/Form'
import { useDispatch, useSelector } from 'react-redux'
import { setEvent } from '../redux/actions/event'
import { setEventDate } from '../redux/actions/eventDate'
import { setExtensions } from '../redux/actions/extensions'
import { setDiscounts } from '../redux/actions/discounts'

const useStyles = makeStyles(theme => ({
  backdrop: {
    color: '#fff',
    display: 'flex',
    flexDirection: 'column'
  }
}))

const FormWrapper = () => {
  const classes = useStyles()

  const step = useSelector(state => state.step)
  const dispatch = useDispatch()

  const history = useHistory()
  const { eventDateId, activeStep } = useParams()

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (activeStep > step) {
      history.replace(`/registration/${eventDateId}/${step}`)
    }
  }, [step])

  useEffect(() => {
    async function getEvent () {
      try {
        if (!eventDateId) {
          throw new Error('No event found')
        }
        const eventDate = await client.service('event-dates').get(eventDateId)
        const event = await client.service('events').get(eventDate.event)

        const extensionIds = eventDate.extensions.map(extension => extension.extension)

        const extensionsRes = await client.service('extensions').find({
          query: {
            _id: {
              $in: extensionIds
            }
          }
        })

        const extensionsMap = {}
        extensionsRes.data.forEach(extension => {
          extensionsMap[extension._id] = extension
        })

        const discountIds = eventDate.discounts.map(discount => discount.discount)

        const discountsRes = await client.service('discounts').find({
          query: {
            _id: {
              $in: discountIds
            }
          }
        })

        const discountsMap = {}
        discountsRes.data.forEach(discount => {
          discountsMap[discount._id] = discount
        })

        dispatch(setEventDate(eventDate))
        dispatch(setEvent(event))
        dispatch(setExtensions(extensionsMap))
        dispatch(setDiscounts(discountsMap))

        setLoading(false)
      } catch (e) {
        history.replace('/404')
      }
    }
    getEvent()
  }, [eventDateId])

  if (loading) {
    return (
      <Backdrop open className={classes.backdrop}>
        <CircularProgress color='primary' />
        <Typography>Trwa ładowanie danych. Proszę czekać.</Typography>
      </Backdrop>
    )
  }

  return (
    <Container>
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <FormStepper activeStep={parseInt(activeStep, 10)} />
          <FormMobileStepper activeStep={parseInt(activeStep, 10)} />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <CampCard />
        </Grid>
        <Grid item xs={12} md={6} lg={8}>
          <Form />
        </Grid>
      </Grid>
    </Container>
  )
}

export default FormWrapper
