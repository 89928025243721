import React from 'react'
import { MobileStepper, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '100%',
    paddingTop: 20,
    marginBottom: -20,
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    },

    '& .MuiMobileStepper-root': {
      backgroundColor: 'transparent',
      position: 'static',
      margin: 'auto'
    }
  }
}))

const steps = ['Dane uczestnika', 'Rozszerzenia oferty', 'Zniżki', 'Podsumowanie']

const FormMobileStepper = ({ activeStep }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <MobileStepper
        activeStep={activeStep}
        steps={4}
        variant='dots'
        backButton={false}
        nextButton={false}
      />
      <Typography variant='h6'>
        {steps[activeStep]}
      </Typography>
    </div>
  )
}

export default FormMobileStepper
