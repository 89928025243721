import React from 'react'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import plLocale from 'date-fns/locale/pl'

const DatePicker = (props) => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={plLocale}>
      <KeyboardDatePicker
        {...props}
        required
        disableFuture
        inputVariant='outlined'
      />
    </MuiPickersUtilsProvider>
  )
}

export default DatePicker
