import { createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import reducers from '../reducers'

export default function configureStore () {
  const enhancer = composeWithDevTools()

  return createStore(
    reducers,
    enhancer
  )
}
