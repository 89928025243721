import feathers from '@feathersjs/client'

const app = feathers()

// Connect to a different URL
const restClient = feathers.rest(process.env.REACT_APP_API_URL)

// Configure an AJAX library (see below) with that client
app.configure(restClient.fetch(window.fetch))

export default app
