import React, { useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'
import {
  Typography,
  Grid,
  Button,
  Checkbox,
  FormControlLabel,
  Card,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Backdrop,
  CircularProgress
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory, useParams } from 'react-router'
import { useSelector } from 'react-redux'
import format from 'date-fns/format'

import client from '../../client'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'

const agreementText = 'Wyrażam zgodę na przetwarzanie podanych w zgłoszeniu danych osobowych w celu realizacji niniejszej rezerwacji (zgodnie z Ustawą z dn. 27.08.1997 r. o Ochronie Danych Osobowych Dz.Ust. Nr 133 poz. 883).'

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiCard-root': {
      marginBottom: theme.spacing(5)
    }
  },
  backdrop: {
    color: '#fff',
    display: 'flex',
    flexDirection: 'column',
    zIndex: 9
  }
}))

const Step3 = props => {
  const classes = useStyles()
  const history = useHistory()
  const { eventDateId } = useParams()

  const formData = useSelector(state => state.formData)
  const extensions = useSelector(state => state.extensions)
  const discounts = useSelector(state => state.discounts)

  const [loading, setLoading] = useState(false)
  const { handleSubmit, register, errors } = useForm()

  const onSubmit = useCallback(async () => {
    setLoading(true)

    const registration = {
      eventDate: eventDateId,
      pesel: formData.pesel,
      email: formData.email,
      firstName: formData.firstName,
      lastName: formData.lastName,
      dateOfBirth: formData.dateOfBirth,
      address: {
        city: formData.addressCity,
        postal: formData.addressPostal,
        street: formData.addressStreet
      },
      phone: formData.phone,
      secondPhone: formData.secondPhone,
      tutor: {
        name: formData.tutorName,
        phone: formData.tutorPhone
      },
      extensions: Object.keys(formData.extensions).map(key => {
        const extension = extensions[key]
        const optionId = formData.extensions[key]
        const option = extension.options.find(option => option._id === optionId)

        return {
          name: extension.name,
          option: option.name,
          price: option.price
        }
      }),
      discounts: discounts[formData.discount] ? [
        discounts[formData.discount]
      ] : [],
      agreements: [agreementText]
    }

    try {
      await client.service('registrations').create(registration)
      history.push('/success')
    } catch (e) {
      setLoading(false)
      history.push('/error')
    }
  }, [])

  const onBack = () => history.push(`/registration/${eventDateId}/2`)

  return (
    <form className={classes.root} onSubmit={handleSubmit(onSubmit)} noValidate>
      <Card>
        <CardHeader
          title='Podsumowanie'
          subheader='Sprawdź poprawność danych'
        />
        <CardContent>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>Imię i nazwisko</TableCell>
                <TableCell>{formData.firstName} {formData.lastName}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Data urodzenia</TableCell>
                <TableCell>{formData.dateOfBirth ? format(formData.dateOfBirth, 'dd/MM/yyyy') : ''}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>PESEL</TableCell>
                <TableCell>{formData.pesel}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Ulica i numer</TableCell>
                <TableCell>{formData.addressStreet}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Miasto</TableCell>
                <TableCell>{formData.addressCity}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Kod pocztowy</TableCell>
                <TableCell>{formData.addressPostal}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Email</TableCell>
                <TableCell>{formData.email}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Telefon 1</TableCell>
                <TableCell>{formData.phone}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Telefon 2</TableCell>
                <TableCell>{formData.secondPhone}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Imię i nazwisko opiekuna</TableCell>
                <TableCell>{formData.tutorName}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Telefon opiekuna</TableCell>
                <TableCell>{formData.tutorPhone}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </CardContent>
      </Card>
      <Card>
        <CardContent>
          <FormControlLabel
            control={
              <Checkbox
                name='agreement'
                inputRef={register({
                  required: 'Zgoda jest wymagana'
                })}
                color='primary'
              />
            }
            label={agreementText}
          />
          <Typography
            color='error'
            variant='caption'
            style={{ opacity: errors.agreement && errors.agreement.message ? 1 : 0 }}
          >
                  Zgoda jest wymagana do rejestracji
          </Typography>
        </CardContent>
      </Card>
      <Grid container justify='space-between'>
        <Grid item>
          <Button
            variant='contained'
            onClick={onBack}
          >
            Powrót
          </Button>
        </Grid>
        <Grid item>
          <Button
            type='submit'
            variant='contained'
            color='primary'
          >
            Zapisz się!
          </Button>
        </Grid>
      </Grid>
      <Backdrop open={loading} className={classes.backdrop}>
        <CircularProgress color='primary' />
        <Typography>Trwa wysyłanie zgłoszenia. Proszę czekać.</Typography>
      </Backdrop>
    </form>
  )
}

export default Step3
