import React from 'react'
import { Typography, Card } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Container from '@material-ui/core/Container'

const useStyles = makeStyles(theme => ({
  root: {
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}))

const Page404 = props => {
  const classes = useStyles()

  return (
    <Container maxWidth='sm' className={classes.root}>
      <Card>
        <CardHeader
          title='404'
          subheader='Ups, coś poszło nie tak :('
        />
        <CardContent>
          <Typography>
            Wygląda na to, że wybrany przez Ciebie obóz nie istnieje w naszej bazie.
            <br />
            <br />
            Spróbuj ponownie za kilka minut, a jeśli błąd będzie się powtarzać, skontaktuj się z nami telefonicznie.
          </Typography>
        </CardContent>
      </Card>
    </Container>
  )
}

export default Page404
